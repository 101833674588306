<template>
  <div class="home">
    <a-button type="primary" @click="showModal" style="margin-bottom: 10px">
      {{ $t("route.AddWorker") }}
    </a-button>
    <a-modal
      :title="$t('route.CreateANewWorker')"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      centered
    >
      <!-- <template #footer>
        <a-button key="back" @click="visible=!visible">Cancel</a-button>
        <a-button key="submit" type="primary" @click="createWorkerSubmit">Submit</a-button> 
      </template> -->
      <a-form :rules="workerRules" :model="formState">
        <!-- :label-col="labelCol"
        :wrapper-col="wrapperCol" -->
        <a-form-item name="WorkerID" :label="$t('route.WorkID')">
          <a-input
            label="Worker ID"
            size="large"
            type="text"
            v-model:value="formState.workerID" 
            :placeholder="$t('route.WorkID')"
          >
            <template #prefix>
              <UserOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />  
            </template>
          </a-input>
        </a-form-item>

        <a-form-item name="WorkerName" :label="$t('route.WorkerName')">
          <a-input
            label="Worker Name"
            size="large"
            type="text"
            v-model:value="formState.name"
            :placeholder="$t('route.WorkerName')"
          />
        </a-form-item>

        <a-form-item name="Department" :label="$t('route.WorkerDepartment')">
          <a-select
            style="width: 120px"
            v-model:value="formState.department"
            placeholder="please select your Department"
            :default-value="{ key: formState.department }">
            <a-select-option value="plating">Plating</a-select-option>
            <a-select-option value="logistic">Logistic</a-select-option>
            <a-select-option value="SAV">SAV</a-select-option>
          </a-select>
        </a-form-item>
        
        <a-form-item name="Barcode" :label="$t('route.Barcode')">
          <a-input-search
            v-model:value="formState.barcode" 
            :placeholder="$t('route.Barcode')"
            :enter-button="$t('route.Generate')"
            size="large"
            label="Barcode"
            type="number"
            @search="onGenerateBarcode"
          />
        </a-form-item>
        <a-form-item name="Role" :label="$t('route.WorkerRole')">
          <a-select 
            v-model:value="formState.role" 
            placeholder="Select Role" 
            style="width: 120px">
            <a-select-option :value="role.Key" :key="role.Key" v-for="role in rma_worker_role_list.data">{{ role.Name }}</a-select-option>
          </a-select>
        </a-form-item>   
        <a-form-item name="IsActive" :label="$t('route.IsActive')">
          <a-checkbox v-model="formState.isActive" @change="formState.isActive = !formState.isActive">  </a-checkbox>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-modal
      :title="$t('route.EditWorker')"
      v-model:visible="visibleEdit"
      :confirm-loading="confirmLoading"
      @ok="updateWorkerSubmit"
    >
      <a-form @submit="updateWorkerSubmit" :model="formState">
        <!-- :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol" -->
        <a-form-item name="WorkerID" :label="$t('route.WorkID')">
          <a-input
            label="Worker ID"
            size="large"
            type="text"
            v-model:value="formState.workerID" 
            :placeholder="$t('route.WorkID')"
          >
            <template #prefix>
              <UserOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />  
            </template>
          </a-input>
        </a-form-item>
        <a-form-item name="WorkerName" :label="$t('route.WorkerName')">
          <a-input
            label="Worker Name"
            size="large"
            type="text"
            v-model:value="formState.name"
            :placeholder="$t('route.WorkerName')"
          >
            <!-- <template #prefix>
                  <LockOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />
                </template> -->
          </a-input>
        </a-form-item>
        <a-form-item name="Department" :label="$t('route.WorkerDepartment')">
          <a-select 
            v-model:value="formState.department" 
            placeholder="please select your Department"
            style="width: 120px">
            <a-select-option value="plating">Plating</a-select-option>
            <a-select-option value="logistic">Logistic</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-form-item name="Barcode" :label="$t('route.Barcode')">
            <a-input-search
              v-model:value="formState.barcode" 
              :placeholder="$t('route.Barcode')"
              :enter-button="$t('route.Generate')"
              size="large"
              label="Barcode"
              type="number"
              @search="onGenerateBarcode"
            />
          </a-form-item>
          <a-form-item name="Role" :label="$t('route.WorkerRole')">
            <a-select 
              v-model:value="formState.role" 
              placeholder="Select Role"
              style="width: 120px">
              <a-select-option :value="role.Key" :key="role.Key" v-for="role in rma_worker_role_list.data">{{ role.Name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item name="IsActive" :label="$t('route.IsActive')">
            <a-checkbox v-model:checked="formState.isActive" @change="changeWorkerStatus()">  </a-checkbox>
          </a-form-item>
      
          <!-- <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
            >
              Update
            </a-button>
          </a-form-item> -->
        
        </a-form-item></a-form>
    </a-modal>

    <!--Divide -->
    <a-table :data-source="rma_workerlist.data" :columns="columns" :pagination="{ pageSize: 30 }" rowKey="ID">
      <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon><SearchOutlined /></template>
            {{ $t('route.Search') }}
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            {{ $t('listRMA.reset') }}
          </a-button>
        </div>
      </template>
      <template #filterIcon="filtered">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #customRender="{ text, column }">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              class="highlight"
              :key="i"
            >
              {{ fragment }}
            </mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          {{ text }}
        </template>
      </template>
      <template #operation="{ record }">
        <span class="table-operation">
          <a @click="showModalEdit(record)">{{ $t("table.edit") }}</a>
          &nbsp&nbsp
          <a-popconfirm title="Sure to delete?" @confirm="onDelete(record)">
            <a>{{ $t("table.delete") }}</a>
          </a-popconfirm>
          <!-- <a>Stop</a>
          <a-dropdown>
            <template #overlay>
              <a-menu>
                <a-menu-item>Action 1</a-menu-item>
                <a-menu-item>Action 2</a-menu-item>
              </a-menu>
            </template>
            <a>
              More
              <down-outlined />
            </a>
          </a-dropdown> -->
        </span>
      </template>
      <template #active="{ record }">
        <span>
          <a-tag v-if="record.isActive == false" :key="record.id" :color="'volcano'">
            {{ $t("route.InActive") }}
          </a-tag>
          <a-tag v-if="record.isActive == true" :key="record.id" :color="'green'">
            {{ $t("route.Active") }}
          </a-tag>
        </span>
      </template>
    </a-table>
  </div>
</template>
<script>
  import { SearchOutlined, UserOutlined } from '@ant-design/icons-vue';
  import { defineComponent, reactive, ref, onMounted } from 'vue';
  import useRmaRepositories from "@/composables/useRmaRepositories"
  import { generateRandom } from '@/utils/utils';
  import { notification } from 'ant-design-vue';
  // import { FormModel } from 'ant-design-vue';
  import { useI18n } from 'vue-i18n'

  //   const data = [
  //     {
  //       key: '1',
  //       name: 'John Brown',
  //       workerID: 32,
  //       barcode: 'New York No. 1 Lake Park',
  //       isActive: 'New York No. 1 Lake Park',
  //       department: 'New York No. 1 Lake Park',
  //       role: 'New York No. 1 Lake Park'
  //     }
  //   ];

  export default defineComponent({
    components: {
      SearchOutlined,
      UserOutlined
    },

    setup() {
      const { t ,locale } = useI18n({ useScope: 'global' })
      const state = reactive({
        searchText: '',
        searchedColumn: '',
      });
      const searchInput = ref();
      const columns = [
        {
          title: t("route.Name"),
          dataIndex: 'name',
          key: 'name',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                // console.log(searchInput.value);
                searchInput.value.focus();
              }, 0);
            }
          },
        },
        {
          title: t("route.WorkID"),
          dataIndex: 'workerid',
          key: 'workerid',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.workerid.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                searchInput.value.focus();
              });
            }
          },
        },
        {
          title: t("route.Barcode"),
          dataIndex: 'barcode',
          key: 'barcode',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.barcode.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                searchInput.value.focus();
              });
            }
          },
        },
        {
          title: t("route.Department"),
          dataIndex: 'department',
          key: 'department',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.department.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                searchInput.value.focus();
              });
            }
          },
        },
        {
          title: t("route.Role"),
          dataIndex: 'role',
          key: 'role',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.role.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                searchInput.value.focus();
              });
            }
          },
        },
        {
          title: t("route.IsActive"),
          dataIndex: 'isActive',
          key: 'isActive',
          slots: { customRender: 'active' } ,
        },
        {
          title: t("route.Action"),
          key:'operation',
          slots: { customRender: 'operation' } ,
    
        }
      ];

      const workerRules = {
        WorkerID: [
          {
            required: true,
            message: 'Worker ID required',
            trigger: 'blur',
          },
        ],
        Barcode: [
          {
            required: true,
            message: 'Please generate a barcode',
            trigger: 'change',
            type: 'object',
          },
          {
            min: 6,
            max: 6,
            message: 'It should be a barcode of 6 numbers',
            trigger: 'blur',
          },
        ],
      };

      // Load Workers List
      const {rma_workerlist , listRmaWorkers, createWorker , deleteWorker, updateWorker, rma_worker_role_list, getAllWorkersRole} = useRmaRepositories()

      onMounted(async () => {
        listRmaWorkers()
        getAllWorkersRole()
      })

      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        state.searchText = selectedKeys[0];
        state.searchedColumn = dataIndex;
      };

      const handleReset = clearFilters => {
        clearFilters();
        state.searchText = '';
      };

      // MODEL CREATE WORKER
      const modalText = ref('Content of the modal');
      const visible = ref(false);
      const confirmLoading = ref(false);

      const showModal = () => {
        resetFormState()
        visible.value = true;
      };

      const formState = reactive({
        id : "",
        name: '',
        workerID:'',
        barcode:'',
        isActive: false,
        department: '',
        role: ''
      });

      const resetFormState = async() => {
        formState.id = "";
        formState.name = "";
        formState.workerID = "";
        formState.barcode = "";
        formState.department = "";
        formState.role = "";
        formState.isActive = false;
      }

      const createWorkerSubmit = async() => {
        if(formState.WorkerID == "" || formState.name == "" || formState.workerID == "" || formState.barcode == "" || formState.department == "" || formState.role == ""){
          // console.log("Please fill all fields",formState)
          notification.error({
            message: 'Please Fill all fields',
            description: "Please Fill all fields"
          })
          return
        }

        const response = await createWorker(formState);
        // console.log("creating worker resutl",response);
        if (response.success == true) {
          notification.open({
            message: 'Create worker complete'
            // description: "Please Fill all fields"
          })
          visible.value = false;
          confirmLoading.value = false;
         
        } else {
          notification.error({
            message: 'Error , creating worker',
            description: response.err.Message
          })
          confirmLoading.value = false;
        }
      }

      const onGenerateBarcode = () => {
        formState.barcode = generateRandom(100000, 999999, 50)
      }

      // MODEL FOR Edit
      const visibleEdit = ref(false);

      const showModalEdit = (record) => {
        resetFormState()
        // console.log("Load edit form record",record)
        formState.id = record.id;
        formState.name = record.WorkerName;
        formState.workerID = record.WorkerID;
        formState.barcode = record.Barcode;
        formState.department = record.Department;
        formState.role = record.Roleobj.Key;
        formState.isActive = record.IsActive;
        visibleEdit.value = true;
      };

      const handleOk = async () => {
        if(formState.workerID == "" || formState.name == "" || formState.workerID == "" || formState.barcode == "" || formState.department == "" || formState.role == ""){
          // console.log("Please fill all fields",formState)
          notification.error({
            message: 'Please Fill all fields',
            description: "Please Fill all fields"
          })
          return
        }
        modalText.value = 'The modal will be closed after two seconds';
        confirmLoading.value = true;
        await createWorkerSubmit ()
        // visible.value = false;
        // confirmLoading.value = false;
      };

      const updateWorkerSubmit = async() => {
        // console.log("creating worker",formState)
        if (formState.workerID == "" || formState.name == "" || formState.workerID == "" || formState.barcode == "" || formState.department == "" || formState.role == "") {
          // console.log("Please fill all fields",formState)
          notification.error({
            message: 'Please Fill all fields',
            description: "Please Fill all fields"
          })
          return
        }
        const response =  await updateWorker(formState);
        // console.log("creating worker resutl",response)
        if (response.success == true) {
          notification.open({
            message: 'Update worker complete'
            // description: "Please Fill all fields"
          })
          visibleEdit.value = false;
        } else {
          notification.error({
            message: 'Error , update worker',
            description: response.err.Message
          })
          //visibleEdit.value = false;
        }
      }

      const changeWorkerStatus = async() =>{
        formState.isActive != formState.isActive;
      }

      const onDelete = async(data) =>{
        const response =  await deleteWorker(data);
      }

      return {
        columns,
        handleSearch,
        handleReset,
        searchText: '',
        searchInput,
        searchedColumn: '',
        rma_workerlist,
        modalText,
        visible,
        visibleEdit,
        confirmLoading,
        showModal,
        showModalEdit,
        formState,
        createWorkerSubmit,
        updateWorkerSubmit,
        onDelete,
        onGenerateBarcode,
        workerRules,
        handleOk,
        rma_worker_role_list,
        changeWorkerStatus,
        t
      };
    },
  });
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
